import _schedulerProduction from "./cjs/scheduler.production.js";
export { _schedulerProduction as default };
export const unstable_now = _schedulerProduction.unstable_now,
  unstable_IdlePriority = _schedulerProduction.unstable_IdlePriority,
  unstable_ImmediatePriority = _schedulerProduction.unstable_ImmediatePriority,
  unstable_LowPriority = _schedulerProduction.unstable_LowPriority,
  unstable_NormalPriority = _schedulerProduction.unstable_NormalPriority,
  unstable_Profiling = _schedulerProduction.unstable_Profiling,
  unstable_UserBlockingPriority = _schedulerProduction.unstable_UserBlockingPriority,
  unstable_cancelCallback = _schedulerProduction.unstable_cancelCallback,
  unstable_continueExecution = _schedulerProduction.unstable_continueExecution,
  unstable_forceFrameRate = _schedulerProduction.unstable_forceFrameRate,
  unstable_getCurrentPriorityLevel = _schedulerProduction.unstable_getCurrentPriorityLevel,
  unstable_getFirstCallbackNode = _schedulerProduction.unstable_getFirstCallbackNode,
  unstable_next = _schedulerProduction.unstable_next,
  unstable_pauseExecution = _schedulerProduction.unstable_pauseExecution,
  unstable_requestPaint = _schedulerProduction.unstable_requestPaint,
  unstable_runWithPriority = _schedulerProduction.unstable_runWithPriority,
  unstable_scheduleCallback = _schedulerProduction.unstable_scheduleCallback,
  unstable_shouldYield = _schedulerProduction.unstable_shouldYield,
  unstable_wrapCallback = _schedulerProduction.unstable_wrapCallback;